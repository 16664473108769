@import url('./reset.css');
@import url('https://use.typekit.net/mdj7jqd.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html, #root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,ul {
  font-family: "bungee",sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  cursor: default;
}

h1 {
  font-size: 42px;
}

h1 + p {
  font-family: "funkydori",sans-serif;
  font-size: 30px;
  color: #333;
  margin-top: -30px;
}

p b {
  font-weight: bold;
}
